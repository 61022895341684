import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { formatInTimeZone } from 'date-fns-tz';


function TaskRatingForm({ formData }) {
  const { id, startDate, duration, task1, task2, task3, task4, task5 } = formData;
  const [ratings, setRatings] = useState([]);
  const [editableRows, setEditableRows] = useState({});
  const today = new Date().toISOString().split('T')[0]; // Get today's date in YYYY-MM-DD format
  const API_URL = process.env.REACT_APP_API_URL;
  
  useEffect(() => {
    const fetchRatings = async () => {
      try {
        const token = localStorage.getItem('authToken');
        const response = await axios.get(`${API_URL}/task/ratings/${id}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        const fetchedRatings = response.data;        

        // Map the fetched ratings
        const initialRatings = fetchedRatings.map((rating) => ({
          ...rating,
          submitted: rating.submitted || false, // Add or ensure 'submitted' field
        }));
        setRatings(initialRatings);
      } catch (error) {
        console.error('Error fetching ratings:', error);
      }
    };
  
    fetchRatings();
  }, [id, startDate, duration]);

  const handleCheckboxChange = (day, field) => {
    setRatings((prevRatings) =>
      prevRatings.map((rating) =>
        rating.day === day ? { ...rating, [field]: !rating[field] } : rating
      )
    );
  };

  const handleDayRatingChange = (day, e) => {
    const value = e.target.value.trim(); // Sanitize input
    const parsedValue = value === '' ? '' : parseInt(value, 10);
    setRatings((prevRatings) =>
      prevRatings.map((rating) =>
        rating.day === day ? { ...rating, day_rating: parsedValue } : rating
      )
    );
  };

  const handleSubmitRow = async (day) => {
    const rating = ratings.find((r) => r.day === day);
    console.log('Found rating:', rating);

    if (!rating || rating.submitted) {
      console.log('Rating not found or already submitted.');
      return;
    }

    try {
      const token = localStorage.getItem('authToken');
      const ratingData = {
        task_dashboard_id: id,
        day: rating.day,
        date: rating.date,
        task1_rating: Number(rating.task1_rating),
        task2_rating: Number(rating.task2_rating),
        task3_rating: Number(rating.task3_rating),
        task4_rating: Number(rating.task4_rating),
        task5_rating: Number(rating.task5_rating),
        day_rating: rating.day_rating ? Number(rating.day_rating) : null,
      };
      // Submit the rating to the API
      await axios.post(        
        `${API_URL}/task/ratings`,
        { rating: ratingData },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // Update the local ratings state
      setRatings((prevRatings) =>
        prevRatings.map((r) =>
          r.day === day ? { ...r, submitted: true } : r
        )
      );
      // Reset the editable row
      setEditableRows((prevEditableRows) => ({
        ...prevEditableRows,
        [day]: false,
      })); 
    } catch (error) {
      console.error('Error submitting rating:', error);
      alert('Error submitting rating: ' + (error.response?.data?.error || error.message));
    }
  };

  const handleEditRow = (day) => {
    // Update editableRows
    setEditableRows((prev) => ({ ...prev, [day]: true }));
  
    // Reset the submitted property for the specific rating
    const updatedRatings = ratings.map((rating) =>
      rating.day === day ? { ...rating, submitted: false } : rating
    );
    setRatings(updatedRatings);
  };

  return (
    <div className="m-3 p-3 task-rating-form">
      <h5 className="text-end">
        Start Date: {formatInTimeZone(new Date(startDate).getTime() + 86400000, 'UTC', 'dd. MMMM')}
        </h5>
      <h6 className="text-end">Duration: {duration} days</h6>
      <form className="mt-3 p-3">
        <div className="table-responsive">
          <table className="w-100">
            <thead>
              <tr>
                <th>Day</th>
                <th>Date</th>
                <th>{task1}</th>
                <th>{task2}</th>
                {/* Dynamically render tasks 3-5 if they are not null */}
                {[task3, task4, task5].map((task, index) =>
                  task ? <th key={`task${index + 3}`}>{task}</th> : null
                )}
                <th style={{maxWidth: '80px'}}>Rate Day</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {ratings.map((rating) => (
                <tr
                  key={rating.day}
                  style={{
                    backgroundColor: rating.submitted
                      ? 'var(--form-background-submitted-color)' 
                      : 'var(--form-background-color)', 
                    color: rating.submitted
                      ? 'var(--form-text-submitted-color)' 
                      : 'var(--form-text-color)'
                  }}
                >
                  <td >
                    {rating.day}.
                  </td>
                  <td >
                    {formatInTimeZone(new Date(rating.date).getTime() + 86400000, 'UTC', 'dd. MMMM')}
                  </td>
                  <td>
                    <i
                      className={`bi bi-circle${rating.task1_rating === null && !rating.submitted ? '' : '-fill'}`}
                      style={{
                        color: rating.task1_rating ? '#26995a' : 'black',
                        fontSize: '20px',
                        cursor: 'pointer',  // Make the icon clickable
                      }}
                      onClick={() => handleCheckboxChange(rating.day, 'task1_rating')}
                    />
                  </td>
                  <td>
                    <i
                      className={`bi bi-circle${rating.task2_rating === null && !rating.submitted ? '' : '-fill'}`}
                      style={{
                        color: rating.task2_rating ? '#26995a' : 'black',
                        fontSize: '20px',
                        cursor: 'pointer',  // Make the icon clickable
                      }}
                      onClick={() => handleCheckboxChange(rating.day, 'task2_rating')}
                    />
                  </td>
                  {/* Dynamically render optional tasks 3-5 if they are not null */}
                  {[task3, task4, task5].map((task, index) =>
                    task ? (
                      <td key={`task${index + 3}`}>
                        <i
                          className={`bi bi-circle${rating[`task${index + 3}_rating`] === null && !rating.submitted ? '' : '-fill'}`}
                          style={{
                            color: rating[`task${index + 3}_rating`] ? '#26995a' : 'black',
                            fontSize: '20px',
                            cursor: 'pointer',
                          }}
                          onClick={() =>
                            handleCheckboxChange(rating.day, `task${index + 3}_rating`)
                          }
                        />
                      </td>
                    ) : null
                  )}
                <td>
                  {/* Show select dropdown only when rating is submitted */}
                  <div>  
                    <input
                      type="range"
                      min="1"
                      max="10"
                      value={rating.day_rating || 1}
                      onChange={(e) => handleDayRatingChange(rating.day, e)}
                      disabled={rating.submitted && !editableRows[rating.day]}
                      style={{ width: '70%' }}                      
                    />
                    <span                       
                      style={{ width: '30%', paddingLeft: '10%' }}
                    >{rating.day_rating || 1}</span> {/* Display the current value */}
                  </div>
                </td>
                  <td>
                    {((rating.date <= today && !rating.submitted) || editableRows[rating.day]) && (
                      <button
                        type="button"
                        onClick={() => handleSubmitRow(rating.day)}
                        style={{
                          background: 'none',
                          border: 'none',
                          padding: 0,
                          cursor: 'pointer',
                        }}
                        title="Submit"
                      >
                        <i className="bi bi-check-circle" style={{ color: '#26995a', fontSize: '24px' }}></i>
                      </button>
                    )}
                    {(rating.submitted && !editableRows[rating.day]) ? (
                      <button 
                        type="button"
                        onClick={() => handleEditRow(rating.day)}
                        style={{
                          background: 'none',
                          border: 'none',
                          padding: 0,
                          cursor: 'pointer',
                        }}
                        title="Edit"
                      >
                        <i className="bi bi-pencil" style={{ color: '#fff', fontSize: '24px' }}></i>
                      </button>
                    ) : null}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </form>
    </div>
  );
}

export default TaskRatingForm;
